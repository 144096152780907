<template>

<div class="menu" :class="{'is-expand': expand}" v-on:click="expand = !expand" v-if="links">

	<router-link :to="$link(link.url)" class="menu-item" v-for="(link, index) in links" :key="index">{{ link.name }}</router-link>

	<app-icon s="chevronDown" v-if="!expand && window.is.mobile" class="menu-icon" />
	<app-icon s="chevronUp" v-if="expand && window.is.mobile" class="menu-icon" />

</div>

</template>

<script>

export default {

	props: ['links'],

	data: function() {

		return {
			expand: false
		}

	}

}

</script>

<style scoped>

.menu-item {
	display: block;
	padding: 20px 0px;
	font-size: 16px;
	line-height: 20px;
	border-bottom: 1px solid #c9c9c9;
}

.menu-icon {
	color: #1A234C;
	font-size: 16px;
	position: absolute;
	right: 16px;
	top: 12px;
	z-index: 2;
}

.is-mobile .menu {
	border: 1px solid #E0E0E0;
	border-radius: 20px;
	background-color: #fff;
	height: 40px;
	padding: 0px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex-basis: 0;
	cursor: pointer;
}

.is-mobile .menu.is-expand {
	height: auto;
}

.is-mobile .menu-item {
	border-bottom: 0px;
	padding: 0px 20px;
	height: 38px;
	line-height: 38px;
	flex-shrink: 0;
	overflow: hidden;
	order: 2;
}

.menu-item.router-link-active,
.menu-item.router-link-exact-active {
	font-weight: 700;
	border-bottom: 2px solid #1D79D1;
	order: 1;
}

.is-mobile .menu-item.router-link-active,
.is-mobile .menu-item.router-link-exact-active {
	order: 1;
	font-weight: 300;
	border-bottom: 0px;
}

</style>
